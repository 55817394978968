"use client";

import React from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { message } from "antd";
import { ProgramStudentModuleTechniqueType } from "../../../../types/dto/program";
import { programsStudentModuleTechniqueAcademy } from "../../../../shared/services";

export interface UseCreateQualificationStudentProps {
  userId: number;
  techniqueId: number;
  onSubmit: UseFormHandleSubmit<ProgramStudentModuleTechniqueType>;
  setOpenModal: (val: boolean) => void
}

export default function useCreateQualificationStudent({
  userId,
  techniqueId,
  onSubmit,
  setOpenModal
}: UseCreateQualificationStudentProps) {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    // await getStudentsByUserIdAndProgramId(userId, data.program_id);
    await programsStudentModuleTechniqueAcademy
      .create({
        user_id: +userId,
        qualification: +data.qualification,
        program_module_technique_id: +techniqueId,
      })
      .then((response) => {
        message.success("Se ha registrado exitosamente la calificion");
        setOpenModal(false)
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    loading,
    handleSubmit,
  };
}
