import { Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTableUser } from "../hooks/useTableUser";
import { IUserResFind } from "../../../redux/@types";
import Table from "../../../shared/components/ui/Table";
import { usersService } from "../../../shared/services";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { useUsers } from "../hooks/useUsers";

const { Title } = Typography;

const query = { $sort: { id: -1 } };

export const TableUsers = () => {
  const { showDeleteConfirm, changeUserForUpdate } = useTableUser();
  const { setTableProps } = useUsers();
  const columns: TableColumns<IUserResFind> = [
    {
      title: "ID",
      width: 60,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombres",
      width: 70,
      dataIndex: "first_name",
      filterType: "data_value",
      key: "first_name",
    },
    {
      title: "Apellidos",
      width: 70,
      dataIndex: "data_value",
      key: "last_name",
      filterType: "text",
    },
    {
      title: "Cédula",
      dataIndex: "dni",
      key: "dni",
      filterType: "text",
      width: 90,
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
      filterType: "text",
      width: 90,
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
      width: 150,
      filterType: "text",
    },
    {
      title: "Rol",
      width: 60,
      dataIndex: "role",
      key: "role",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
          { item.role === "user" && `Usuario`}
          { item.role === "admin" && `Administrador`}
          { item.role === "seller" && `Vendedor`}
          { item.role === "domiciliary" && `Domiciliario`}
          </Col>
        );
      },
      filters: [
        {
          text: "Administrador",
          value: "admin",
        },
        {
          text: "Usuarios",
          value: "user",
        },
        {
          text: "Vendedor",
          value: "seller",
        },
      ],
    },
    {
      title: "Estudiante / Profesor",
      width: 60,
      dataIndex: "student_teacher",
      key: "student_teacher",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
          { item.student_teacher === "student" && `Estudiante`}
          { item.student_teacher === "teacher" && `Profesor`}
          </Col>
        );
      },
      filters: [
        {
          text: "Estudiante",
          value: "student",
        },
        {
          text: "Profesor",
          value: "teacher",
        },
      ],
    },
    {
      title: "Codigo estudiantil",
      width: 60,
      dataIndex: "student_code",
      key: "student_code",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
          { item.status === "active" && `Activo`}
          { item.status === "inactive" && `Inactivo`}
          { item.status === "pending security verification" && `Verificación de seguridad pendiente`}
          { item.status === "pending user data" && `Datos de usuario pendientes`}
          { item.status === "pending validation" && `Validación pendiente`}
          </Col>
        );
      },
      filters: [
        { value: "active", text: "Activo" },
        { value: "inactive", text: "Inactivo" },
        {
          value: "pending security verification",
          text: "verificación de seguridad pendiente",
        },
        { value: "pending user data", text: "datos de usuario pendientes" },
        { value: "pending validation", text: "Validación pendiente" },
      ],
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
      width: 80,
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeUserForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() =>
                showDeleteConfirm(
                  item.id,
                  `${item.first_name} ${item.last_name}`
                )
              }
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<IUserResFind>
      columns={columns}
      fetchQueryProps={query}
      service={usersService}
      onLoad={setTableProps}
    />
  );
};
