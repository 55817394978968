import React from "react";
import { programPaymenAcademy, programsStudentAcademy } from "../../../../shared/services";
import { message } from "antd";

export default function useGenerateCsv() {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (startDate: string, endDate: string) => {
    if (loading) return;
    try {
      const record = await programPaymenAcademy.find({
        query: { $client: {startDate, endDate, exportToExcel: 'true'} },
      }).then((res) => {
        window.open(res.data[0].objectUrl, '_blank')
    });

      console.log(record);
      return record;
    } catch (err) {
      message.error("Error al crear el pago");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSubmit,
  };
}
