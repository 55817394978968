import { Controller, type Control } from "react-hook-form";
import {
  ProgramModuleType,
  ProgramStudentModuleTechniqueType,
  ProgramStudentType,
  ProgramTechniquesType,
  ProgramTopicType,
} from "../../../../types/dto/program";
import { Col, Select, Typography } from "antd";
import {
  programsModuleAcademy,
  programsModuleTechniquesAcademy,
  programsStudentAcademy,
  programsTopicsAcademy,
} from "../../../../shared/services";
import useFilterProgramStudent from "../../hooks/programStudent/useFilterProgramStudent";
import { InputNumber } from "../../../../shared/components";

export default function CreateStudentQualificationForm({
  control,
  programStudentByUserIdData,
  programModuleTopicByModuleId,
  programModuleByProgramId,
  programTechniqueByTopicId,
  userId,
  programId,
  moduleId,
  topicId,
}: {
  control?: Control<ProgramStudentModuleTechniqueType>;
  programStudentByUserIdData: ProgramStudentType[];
  programModuleByProgramId: ProgramModuleType[];
  programModuleTopicByModuleId: ProgramTopicType[];
  programTechniqueByTopicId: ProgramTechniquesType[];
  userId: number;
  programId: number;
  moduleId: number;
  topicId: number;
}) {
  const { query, setQuery, filteredData } =
    useFilterProgramStudent<ProgramStudentType>({
      service: programsStudentAcademy,
      initialData: programStudentByUserIdData,
      queryKey: "q",
      params: "user_id",
      parasValue: userId,
      skip: true,
    });

  const { setQuery: setQueryModule, filteredData: filteredDataModule } =
    useFilterProgramStudent<ProgramModuleType>({
      service: programsModuleAcademy,
      initialData: programModuleByProgramId,
      queryKey: "q",
      params: "program_id",
      parasValue: programId,
      skip: false,
    });

  const {
    setQuery: setQueryModuleTopics,
    filteredData: filteredDataModuleTopics,
  } = useFilterProgramStudent<ProgramTopicType>({
    service: programsTopicsAcademy,
    initialData: programModuleTopicByModuleId,
    queryKey: "q",
    params: "program_module_id",
    parasValue: moduleId,
    skip: false,
  });

  const {
    setQuery: setQueryModuleTechniques,
    filteredData: filteredDataModuleTechniques,
  } = useFilterProgramStudent<ProgramTechniquesType>({
    service: programsModuleTechniquesAcademy,
    initialData: programTechniqueByTopicId,
    queryKey: "q",
    params: "program_module_topic_id",
    parasValue: topicId,
    skip: false,
  });

  return (
    <div className="flex flex-col gap-3">
      <Controller
        control={control}
        name="program_id"
        render={({ field, fieldState }) => (
          <div>
            <label>Selecciona un programa</label>
            <Select
              {...field}
              showSearch
              style={{ width: 270 }}
              placeholder="Selecciona el programa"
              optionFilterProp="label"
              onSearch={(value) => setQuery(value)}
              options={
                filteredData?.length > 0
                  ? filteredData.map((it) => ({
                      key: `${it.program_id}`,
                      value: `${it.program_id}`,
                      label: it.program.name,
                    }))
                  : [
                      {
                        key: "",
                        value: "",
                        label: "No hay programas disponibles",
                      },
                    ]
              }
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
      <Controller
        control={control}
        name="module_id"
        render={({ field, fieldState }) => (
          <div>
            <label>Selecciona el modulo</label>
            <Select
              {...field}
              disabled={programModuleByProgramId.length === 0}
              showSearch
              style={{ width: 270 }}
              placeholder="Selecciona el modulo"
              optionFilterProp="label"
              onSearch={(value) => setQueryModule(value)}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
              options={
                filteredDataModule?.length > 0
                  ? filteredDataModule.map((it: ProgramModuleType) => ({
                      key: it.id,
                      value: `${it.id}`,
                      label: it.name,
                    }))
                  : [
                      {
                        key: "no-options", // Agrega un valor para "key" aquí
                        value: "",
                        label: "No hay modulos disponibles",
                      },
                    ]
              }
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
      <Controller
        control={control}
        name="topic_id"
        render={({ field, fieldState }) => (
          <div>
            <label>Selecciona el tema</label>
            <Select
              {...field}
              showSearch
              disabled={programModuleTopicByModuleId.length > 0 ? false : true}
              style={{ width: 270 }}
              placeholder="Selecciona el tema"
              optionFilterProp="label"
              onSearch={(value) => setQueryModuleTopics(value)}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
              options={
                filteredDataModuleTopics?.length > 0
                  ? filteredDataModuleTopics.map((it: ProgramTopicType) => ({
                      key: it.id,
                      value: `${it.id}`,
                      label: it.name,
                    }))
                  : [
                      {
                        key: 0, // Asigna un valor fijo a `key` cuando no haya opciones disponibles
                        value: "",
                        label: "No hay temas disponibles",
                      },
                    ]
              }
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
      <Controller
        control={control}
        name="technique_id"
        render={({ field, fieldState }) => (
          <div>
            <label>Selecciona la técnica</label>
            <Select
              {...field}
              showSearch
              disabled={programTechniqueByTopicId.length === 0}
              style={{ width: 270 }}
              placeholder="Selecciona la técnica"
              optionFilterProp="label"
              onSearch={(value) => setQueryModuleTechniques(value)}
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
              options={
                filteredDataModuleTechniques.length > 0
                  ? filteredDataModuleTechniques.map(
                      (it: ProgramTechniquesType) => ({
                        key: it.id,
                        value: `${it.id}`,
                        label: it.name,
                      })
                    )
                  : [
                      {
                        key: 0, // Asigna un valor fijo a `key` cuando no haya opciones disponibles
                        value: "",
                        label: "No hay técnicas disponibles",
                      },
                    ]
              }
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <label >
          Calificación
        </label>
        <InputNumber
          placeHolder="3"
          name="qualification"
          control={control}
          max={5.0}
          min
        />
      </Col>
      
    </div>
  );
}
