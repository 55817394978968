import { Controller, type Control } from "react-hook-form";
import {ProgramStudentModuleTechniqueType,} from "../../../../types/dto/program";
import { Col, Select } from "antd";
import { InputNumber } from "../../../../shared/components";
 
export default function EditStudentQualificationForm({
  control,
}: {
  control?: Control<ProgramStudentModuleTechniqueType>;
}) {

  return (
    <div className="flex flex-col gap-3">
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <label >
          Calificación
        </label>
        <InputNumber
          placeHolder="3"
          name="qualification"
          control={control}
          max={5.0}
          min
        />
      </Col>
    </div>
  );
}
